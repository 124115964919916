import type { DrawerSettings, DrawerStore } from '@skeletonlabs/skeleton';

import { defaultDrawerSettings } from '@tickrr/ui/components/v2/elements/drawer/defaultSettings.ts';

export const chartPageDrawerSettings: DrawerSettings = {
	...defaultDrawerSettings.bottom,
	height: 'h-full max-h-[95dvh]',
	id: 'chart-page-drawer',
	regionDrawer: 'p-0 !overflow-visible'
};

export function openChartPageDrawer({ drawerStore }: { drawerStore: DrawerStore }) {
	drawerStore.open(chartPageDrawerSettings);
}
